/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisabledControlDirective } from './disabled.directive';
import { InputComponent } from './input.component';

@NgModule({
  declarations: [InputComponent, DisabledControlDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    DisabledControlDirective,
  ],
})
export class InputModule {}
