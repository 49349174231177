import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Directive({
  selector: '([ngModel], [formControlName], [formControl])[disabledControl]',
})
export class DisabledControlDirective {
  @Input() set disabledControl(state: boolean) {
    of(null)
      .pipe(
        delay(0),
        tap(() => {
          const action = state ? 'disable' : 'enable';
          this.ngControl?.control?.[action]();
        }),
      )
      .subscribe();
  }

  constructor(private readonly ngControl: NgControl) {}
}
