export default function autoCorrectDateFormat(dateFormat = 'mm/dd/yyyy') {
  return (value) => {
    const date_regex = [
      {
        regex: /^(0[1-9]|1[0-2])/,
        mask: [/(0|1)/, /\d/, '/'],
      },
      // {
      //   regex: /\/(0[1-9]|1\d|2\d|3[01])/,
      //   mask: [/\d/, /\d/, '/'],
      // },
      {
        regex: /\/([0-9]{4})$/,
        mask: [/\d/, /\d/, /\d/, /\d/],
      },
    ];
    const pass = [].concat(
      ...date_regex.map(({ regex, mask }) =>
        new RegExp(regex).test(value) ? mask : [],
      ),
    );
    const regcount: number[] = date_regex.map((a) => a['mask'].length);
    const final_length = regcount.reduce((a, b) => a + b, 0);
    if (pass.length < final_length)
      for (let i = 0; i < Math.max(...regcount); i++) pass.push(/\d/);
    return pass;
  };
}
