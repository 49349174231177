import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppInitializerProvider } from './app.initializer.provider';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from './components/input';
import { TextMaskDirectiveModule } from './components/directives/text-mask';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputModule,
    TextMaskDirectiveModule,
    TranslateModule.forRoot(),
  ],
  declarations: [AppComponent],
  exports: [],
  providers: [
    ...AppInitializerProvider,
    provideHttpClient(withFetch()),
    provideClientHydration(),
  ],
})
export class AppModule {}
