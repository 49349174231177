import { AfterContentInit, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  formValidate,
  markAsPristine,
  setError,
} from './utilities/form.validation';
import autoCorrectDateFormat from './components/directives/text-mask/autoCorrectDateFormat';

export type TAccTypes = 'checking' | 'debitCard';

@Component({
  selector: '[app-root]',
  templateUrl: `./app.component.html`,
  styleUrls: [`./app.component.scss`],
})
export class AppComponent implements AfterContentInit {
  public type: TAccTypes = 'checking';
  public thankYou: boolean = false;

  public dateTextMask = autoCorrectDateFormat();

  private checkingCtrl = ['routingNumber', 'bankAccount', 'confirmBankAccount'];
  private debitCardCtrl = ['cardNumber', 'nameOnCard', 'exp', 'cvv'];
  private isNumbers = [
    'routingNumber',
    'bankAccount',
    'confirmBankAccount',
    'cardNumber',
    'cvv',
  ];

  public formGroup = new FormGroup(
    {
      loanAccountNumber: new FormControl(undefined, [Validators.required]),

      // Checking
      routingNumber: new FormControl(undefined, []),
      bankAccount: new FormControl(undefined, []),
      confirmBankAccount: new FormControl('', []),

      // debitCard
      cardNumber: new FormControl(undefined, []),
      nameOnCard: new FormControl(undefined, []),
      exp: new FormControl(undefined, []),
      cvv: new FormControl(undefined, []),
    },
    {
      validators: [stringEquals('bankAccount', 'confirmBankAccount')],
    },
  );

  getController(name: string) {
    const controller: FormControl = this.formGroup.controls?.[name];
    return controller.invalid && controller.dirty;
  }

  onTypeChange(e: TAccTypes) {
    this.type = e;
    this.thankYou = false;
    const loanAccountNumber = this.formGroup.value.loanAccountNumber;
    this.formGroup.reset();
    this.formGroup.patchValue({ loanAccountNumber });

    const reset = [...this.checkingCtrl, ...this.debitCardCtrl];

    reset.forEach((ctrl) => {
      setError(this.formGroup.controls[ctrl], 'required', false);
      this.formGroup.controls[ctrl].removeValidators(Validators.required);
    });

    if (this.type === 'checking') {
      this.checkingCtrl.forEach((ctrl) => {
        this.formGroup.controls[ctrl].addValidators(Validators.required);
      });
    } else {
      this.debitCardCtrl.forEach((ctrl) => {
        this.formGroup.controls[ctrl].addValidators(Validators.required);
      });
    }
  }

  isValidNumber(ctrlName: string) {
    const ACtrl = <FormControl>this.formGroup.controls[ctrlName];
    const k = ACtrl.value;
    const isnum =
      k && typeof k !== 'number' && k?.length ? !isNaN(Number(k)) : false;
    setError(ACtrl, 'notNumber', !isnum);
  }

  ngAfterContentInit(): void {
    this.onTypeChange(this.type);
  }

  makePayment() {
    if (!formValidate(this.formGroup)) return;
    const rawValue = this.formGroup.getRawValue();
    const formSubmit = {
      type: this.type,
      ...rawValue,
    };

    this.thankYou = true;
    console.log('formSubmit', formSubmit);
  }
}

export function mustBeNumber(a: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ACtrl = <FormControl>control.get(a);
    const k = ACtrl.value;
    const isnum =
      k && typeof k !== 'number' && k?.length ? !isNaN(Number(k)) : false;
    setError(ACtrl, 'notNumber', !isnum);
    return null;
  };
}

export function stringEquals(a: string, b: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ACtrl = <FormControl>control.get(a);
    const BCtrl = <FormControl>control.get(b);
    const AValue = ACtrl.value;
    const BValue = BCtrl.value;

    if (AValue && !BValue) {
      setError(BCtrl, 'notEqual', true);
    } else if (BValue && !AValue) {
      setError(ACtrl, 'notEqual', true);
    } else if (AValue !== BValue) {
      setError(ACtrl, 'notEqual', true);
      setError(BCtrl, 'notEqual', true);
    } else if (AValue && BValue && AValue === BValue) {
      setError(ACtrl, 'notEqual', false);
      setError(BCtrl, 'notEqual', false);
    }

    return null;
  };
}
