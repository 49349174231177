import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { get } from 'lodash';

export function formValidate(formGroup: FormGroup) {
  if (formGroup.invalid) {
    Object.keys(formGroup.controls).forEach((k) => {
      const control = formGroup.controls[k];
      if (control instanceof FormControl) {
        if (formGroup.controls[k].value) return;
        markAsDirty(formGroup.controls[k]);
      } else if (control instanceof FormArray) {
        for (let formGroup of control.controls) {
          formValidate(<FormGroup>formGroup);
        }
      }
    });
    return false;
  }
  return true;
}

export function formValidateObserver(formGroup: FormGroup) {
  return timer(1).pipe(
    take(1),
    map((a) => formValidate(formGroup)),
  );
}

export function markAsDirty(controls) {
  controls.markAsDirty({ onlySelf: true });
  controls.markAsTouched({ onlySelf: true });
  controls.updateValueAndValidity({ emitEvent: true });
  return controls;
}

export function markAsPristine(controls) {
  controls.markAsPristine({ onlySelf: true });
  controls.markAsUntouched({ onlySelf: true });
  controls.updateValueAndValidity({ emitEvent: true });
  return controls;
}

export function getFormGroupRawValue(formGroup, key) {
  return get(formGroup.getRawValue(), key);
}

export function buildFormGroupController(
  formGroup,
  params,
  addControl: boolean = false,
) {
  if (!params) return;
  Object.keys(params).forEach((k) => {
    const control = formGroup.controls[k];
    if (control) control.setValue(params[k]);
    else if (addControl) formGroup.addControl(k, new FormControl(params[k]));
  });
}

export function setError(
  control: AbstractControl,
  key: string,
  value: boolean,
) {
  let errors = control.errors;
  if (!errors) {
    if (!value) {
      control.setErrors(null);
    } else {
      let error = {};
      error[key] = value;
      control.setErrors(error);
    }
  } else {
    if (!value) {
      if (errors.hasOwnProperty(key)) {
        delete errors[key];
      }
      let errorsKey = Object.getOwnPropertyNames(errors);
      if (errorsKey.length <= 0) {
        control.setErrors(null);
      } else {
        control.setErrors(errors);
      }
    } else {
      errors[key] = value;
      control.setErrors(errors);
    }
  }
}
