<div class="container">
  <div class="row mt-4 mb-29px">
    <div class="col-12">
      <header class="h1">{{ "oneTimeHeader" | translate }}</header>
      <small class="sub-header">{{ "oneTimeSubHeader" | translate }}</small>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="row g-0 border border-ddd">
      <div class="col-12 h-60 border-bottom border-ddd px-7px">
        <sa-input
          formControlName="loanAccountNumber"
          type="number"
          [label]="'loanAccNum' | translate"
        >
        </sa-input>
      </div>
      <div
        *ngIf="getController('loanAccountNumber')"
        class="border-bottom col-12 text-danger bg-danger f-12px px-7px"
      >
        {{ "loanAccNumWarn" | translate }}
      </div>
      <div class="row g-0">
        <div class="col-md-6 col-sm-12">
          <div
            class="row g-0 h-60 border-bottom border-end border-sm-end border-ddd"
          >
            <div class="col px-7px">
              <label
                [innerHTML]="'typeOfAcc' | translate"
                class="form-label label no-pointer-events"
              ></label>
              <div class="d-flex flex-row pb-9px">
                <div class="form-check me-4">
                  <input
                    class="form-check-input cursor-pointer"
                    [checked]="type === 'checking'"
                    (click)="onTypeChange('checking')"
                    type="radio"
                    name="checking"
                    id="checking"
                  />
                  <label
                    class="form-check-label cursor-pointer f-14px mb-9px"
                    for="checking"
                  >
                    {{ "checking" | translate }}
                  </label>
                </div>
                <div class="form-check me-4">
                  <input
                    class="form-check-input cursor-pointer"
                    [checked]="type === 'debitCard'"
                    (click)="onTypeChange('debitCard')"
                    type="radio"
                    name="debitCard"
                    id="debitCard"
                  />
                  <label
                    class="form-check-label cursor-pointer f-14px"
                    for="debitCard"
                  >
                    {{ "debitCard" | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="type === 'checking'; else debitcard">
            <ng-container *ngTemplateOutlet="checking"></ng-container>
          </ng-container>
        </div>
        <div
          class="col-md-6 px-md-4 pt-md-2 py-4 col-sm-12 bg-light-blue d-flex justify-content-center align-items-center"
        >
          @if (type === "checking") {
            <div class="text-center f-bold d-flex flex-column">
              <span class="f-14px f-bold">{{ "whereAccNum" | translate }}</span>
              <img src="/assets/images/check.png" />
            </div>
          } @else {
            <div class="text-center f-bold d-flex flex-column">
              <span class="f-14px f-bold">{{ "whereCVV" | translate }}</span>
              <img src="/assets/images/cvv.png" />
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row mb-4 gx-0">
      <div class="col-12 mt-20px">
        <button
          type="button"
          class="btn w-md-100 btn-primary rounded-0 f-14px f-bold"
          (click)="makePayment()"
        >
          {{ "makePaymentBtn" | translate | uppercase }}
        </button>
        <span *ngIf="thankYou">Thank You.</span>
      </div>
    </div>

    <ng-template #checking>
      <div class="row border-bottom g-0 border-end border-sm-end border-ddd">
        <div class="col-12 h-60 border-ddd px-7px">
          <sa-input
            formControlName="routingNumber"
            [label]="'routingNum' | translate"
            [maxlength]="9"
            type="tel"
            (change)="isValidNumber('routingNumber')"
          >
          </sa-input>
        </div>

        <div
          *ngIf="getController('routingNumber')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "routingWarn" | translate }}
        </div>
      </div>
      <div class="row border-bottom g-0 border-end border-sm-end border-ddd">
        <div class="col-12 h-60 border-ddd px-7px">
          <sa-input
            formControlName="bankAccount"
            (change)="isValidNumber('bankAccount')"
            [label]="'bankNum' | translate"
            type="tel"
          >
          </sa-input>
        </div>
        <div
          *ngIf="getController('bankAccount')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "bankNumWarn" | translate }}
        </div>
      </div>
      <div
        class="row g-0 border-md-0 border-bottom border-end border-sm-end border-ddd"
      >
        <div class="col-12 h-60 border-ddd px-7px">
          <sa-input
            formControlName="confirmBankAccount"
            (change)="isValidNumber('confirmBankAccount')"
            [label]="'confirmBankNumr' | translate"
            type="tel"
          >
          </sa-input>
        </div>
        <div
          *ngIf="getController('confirmBankAccount')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "confirmBankNumrWarn" | translate }}
        </div>
      </div>
    </ng-template>

    <ng-template #debitcard>
      <div class="row g-0 border-bottom border-end border-sm-end border-ddd">
        <div class="col-12 h-60 border-ddd px-7px">
          <sa-input
            formControlName="cardNumber"
            (change)="isValidNumber('cardNumber')"
            [label]="'cardNum' | translate"
            type="tel"
          >
          </sa-input>
        </div>
        <div
          *ngIf="getController('cardNumber')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "cardNumberWarn" | translate }}
        </div>
      </div>
      <div class="row g-0 border-bottom border-end border-sm-end border-ddd">
        <div class="col-12 h-60 border-ddd px-7px">
          <sa-input
            formControlName="nameOnCard"
            [label]="'nameCard' | translate"
          >
          </sa-input>
        </div>
        <div
          *ngIf="getController('nameOnCard')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "nameCardWarn" | translate }}
        </div>
      </div>
      <div
        class="row g-0 border-md-0 border-bottom border-end border-sm-end border-ddd"
      >
        <div class="col-6 h-60 border-end border-ddd px-7px">
          <sa-input
            formControlName="exp"
            type="tel"
            [textMask]="dateTextMask"
            [label]="'exp' | translate"
          >
          </sa-input>
        </div>
        <div class="col-6 h-60 border-ddd px-7px">
          <sa-input
            [maxlength]="3"
            formControlName="cvv"
            (change)="isValidNumber('cvv')"
            [label]="'cvv' | translate"
            type="tel"
          >
          </sa-input>
        </div>

        <div
          *ngIf="getController('exp') || getController('cvv')"
          class="col-12 border-top text-danger bg-danger f-12px px-7px"
        >
          {{ "expCvvWarn" | translate }}
        </div>
      </div>
    </ng-template>
  </form>
</div>
