/**
 * Created by reyra on 5/21/2017.
 */

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AbstractControlDirective,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { getUniqueId } from '../../utilities/getUniqueId';

type InputModelTypes = string | number | null;

@Component({
  selector: 'sa-input[formControlName],sa-input[formControl],sa-input[ngModel]',
  styleUrls: ['./input.component.scss'],
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent
  implements
    OnInit,
    OnChanges,
    ControlValueAccessor,
    AfterViewInit,
    AfterContentInit
{
  @HostBinding('class.no-spinners') noSpinners: boolean = true;
  @HostBinding('class.relative') classRelative: boolean = true;

  @ViewChild('inputGroup', { static: true }) inputGroup: ElementRef;

  @Input() label: string = '';

  //https://www.w3.org/TR/html401/interact/forms.html
  //Chrome maxlength 512KB
  @Input() maxlength: number = 524288; //default value
  @Input() minlength: number = 0;
  @Input() min: number = 524288 * -1;
  @Input() max: number = 524288;
  @Input() autocomplete: string = '';
  @Input() autocorrect: string;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() id: string = getUniqueId(1);
  @Input() name: string = '';
  @Input() inputClass: string = '';
  @Input() pattern: string = '';
  @Input() required: boolean = false;

  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() NgModel: EventEmitter<AbstractControlDirective> =
    new EventEmitter<AbstractControlDirective>();

  public _model: InputModelTypes;
  public hasPrepend: boolean = false;
  public hasAppend: boolean = false;
  public invalid: boolean = false;
  public dirty: boolean = false;

  public ctrl: AbstractControl;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
  ) {}

  ngAfterContentInit(): void {
    this.renderer.removeAttribute(this.LayoutElement, 'type');
  }

  private get LayoutElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  onFocus(e) {
    this.focus.emit(e);
  }

  onBlur(e) {
    this.blur.emit(e);
  }

  ngOnInit(): void {}

  get InputElement(): HTMLInputElement {
    return this.elementRef.nativeElement.querySelector('input');
  }

  ngOnChanges(changes: SimpleChanges): void {}

  modelChanged(e: InputModelTypes) {
    // if (e === this.ngValue) return
    this.ngValue = e;
    this.onChangeCallback(this.ngValue);
    this.onTouchedCallback(this.ngValue);
  }

  set ngValue(e: InputModelTypes) {
    const isUndefined = e === '' || e === null;
    if (isUndefined) {
      this._model = null;
    } else {
      this._model = this.type === 'number' ? +e : e;
    }
  }

  get ngValue() {
    return this._model;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(value: string | number): void {
    this.ngValue = value;
  }

  onValidationChange: any = () => {};

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(ctrl: AbstractControl): ValidationErrors | null {
    this.ctrl = ctrl;
    const errors = <ValidationErrors>ctrl?.errors;
    this.required = errors?.required ?? false;
    this.maxlength = errors?.maxlength?.requiredLength ?? this.maxlength;
    this.minlength = errors?.minlength?.requiredLength ?? this.minlength;
    this.pattern = errors?.pattern?.requiredPattern;

    return null;
  }

  private onTouchedCallback = (v: any) => {};

  private onChangeCallback = (v: any) => {};

  isInvalid(ctrl) {
    return ctrl && ctrl.invalid && ctrl.dirty;
  }

  ngAfterViewInit(): void {}
}
